import styles from './Link.css'

export function LinkWithoutUnderline({ href, dataX, dataXContext = undefined, children, layoutClassName = undefined, target = undefined }) {
  return (
    <LinkBase
      className={cx(styles.componentWithoutUnderline, layoutClassName)}
      {...{ href, dataX, dataXContext, target }}
    >
      {children}
    </LinkBase>
  )
}

export function LinkWithUnderline({ href, dataX, dataXContext = undefined, children, layoutClassName = undefined, target = undefined }) {
  return (
    <LinkBase
      className={cx(styles.componentWithUnderline, layoutClassName)}
      {...{ href, dataX, dataXContext, target }}
    >
      {children}
    </LinkBase>
  )
}

export function Link({ href, dataX, dataXContext = undefined, children, layoutClassName = undefined, target = undefined }) {
  return (
    <LinkBase
      className={cx(styles.component, layoutClassName)}
      {...{ href, dataX, dataXContext, target }}
    >
      {children}
    </LinkBase>
  )
}

function LinkBase({ href, dataX, dataXContext, children, target = undefined, className }) {
  const rel = target === '_blank' ? 'noopener noreferrer' : null
  return (
    <a
      data-x={dataX}
      data-x-context={dataXContext}
      className={cx(styles.componentBase, className)}
      {...{ href, rel, target }}
    >
      {children}
    </a>
  )
}
